type Team = {
  id: number;
  fullName: string;
  abbreviation: string;
};

export const Teams: Record<string, Team> = {
  1: {
    id: 1,
    fullName: "New Jersey Devils",
    abbreviation: "NJD",
  },
  2: {
    id: 2,
    fullName: "New York Islanders",
    abbreviation: "NYI",
  },
  3: {
    id: 3,
    fullName: "New York Rangers",
    abbreviation: "NYR",
  },
  4: {
    id: 4,
    fullName: "Philadelphia Flyers",
    abbreviation: "PHI",
  },
  5: {
    id: 5,
    fullName: "Pittsburgh Penguins",
    abbreviation: "PIT",
  },
  6: {
    id: 6,
    fullName: "Boston Bruins",
    abbreviation: "BOS",
  },
  7: {
    id: 7,
    fullName: "Buffalo Sabers",
    abbreviation: "BUF",
  },
  8: {
    id: 8,
    fullName: "Montreal Canadiens",
    abbreviation: "MTL",
  },
  9: {
    id: 9,
    fullName: "Ottowa Senators",
    abbreviation: "OTT",
  },
  10: {
    id: 10,
    fullName: "Toronto Maple Leafs",
    abbreviation: "TOR",
  },
  12: {
    id: 12,
    fullName: "Carolina Hurricanes",
    abbreviation: "CAR",
  },
  13: {
    id: 13,
    fullName: "Florida Panthers",
    abbreviation: "",
  },
  14: {
    id: 14,
    fullName: "Tampa Bay Lightning",
    abbreviation: "TBL",
  },
  15: {
    id: 15,
    fullName: "Washington Capitals",
    abbreviation: "WSH",
  },
  16: {
    id: 16,
    fullName: "Chicago Blackhawks",
    abbreviation: "CHI",
  },
  17: {
    id: 17,
    fullName: "Detroit Red Wings",
    abbreviation: "DET",
  },
  18: {
    id: 18,
    fullName: "Nashville Predators",
    abbreviation: "NSH",
  },
  19: {
    id: 19,
    fullName: "St. Louis Blues",
    abbreviation: "STL",
  },
  20: {
    id: 20,
    fullName: "Calgary Flames",
    abbreviation: "CGY",
  },
  21: {
    id: 21,
    fullName: "Colorado Avalanche",
    abbreviation: "COL",
  },
  22: {
    id: 22,
    fullName: "Edmonton Oilers",
    abbreviation: "EDM",
  },
  23: {
    id: 23,
    fullName: "Vancouver Canucks",
    abbreviation: "VAN",
  },
  24: {
    id: 24,
    fullName: "Anaheim Ducks",
    abbreviation: "ANA",
  },
  25: {
    id: 25,
    fullName: "Dallas Stars",
    abbreviation: "DAL",
  },
  26: {
    id: 26,
    fullName: "Los Angeles Kings",
    abbreviation: "LAK",
  },
  28: {
    id: 28,
    fullName: "San Jose Sharks",
    abbreviation: "SJS",
  },
  29: {
    id: 29,
    fullName: "Columbus Blue Jackets",
    abbreviation: "CBJ",
  },
  30: {
    id: 30,
    fullName: "Minnesota Wild",
    abbreviation: "MIN",
  },
  52: {
    id: 52,
    fullName: "Winnipeg Jets",
    abbreviation: "WPG",
  },
  53: {
    id: 53,
    fullName: "Arizona Coyotes",
    abbreviation: "ARI",
  },
  54: {
    id: 54,
    fullName: "Vegas Golden Knights",
    abbreviation: "VGK",
  },
  55: {
    id: 55,
    fullName: "Seattle Kraken",
    abbreviation: "SEA",
  },
};
