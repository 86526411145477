import { Teams } from "../../constants/Teams";
import { getRosterByTeamId } from "./getRosterByTeamId";

export interface PlayerOptionType {
  label: string;
  id?: string;
}

export const getLeague = () => {
  const league: PlayerOptionType[] = [];
  Object.keys(Teams).forEach(async (id) => {
    const team = await getRosterByTeamId(id);
    for (const player in team) {
      league.push({
        label: team[player].fullName + ` (${Teams[id].abbreviation})`,
        id: team[player].id,
      });
    }
  });
  return league;
};
