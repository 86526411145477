import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { BasePage } from "../components/BasePage";
import { getLeague, PlayerOptionType } from "../services/nhl-api/getLeague";
import { Stats, getPointsById } from "../services/nhl-api/getPointsById";

const SearchBar = styled.div`
  display: flex;
  justify-content: center;
`;

const StatsStyle = styled.div`
  text-align: center;
`;

export const PlayerStatsPage = () => {
  const [value, setValue] = useState<PlayerOptionType | null>(null);
  const [stats, setStats] = useState<Stats | null>(null);
  const filter = createFilterOptions<PlayerOptionType>();

  const handlePlayerSelect = async () => {
    console.log("select handler called");
    if (value?.label) {
      const allPoints = await getPointsById(value?.id);
      console.log(allPoints);
      setStats(allPoints);
    }
  };
  return (
    <BasePage pageTitle="Players">
      <br></br>
      <SearchBar>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setValue({
                label: newValue,
              });
            } else {
              setValue(newValue);
            }
          }}
          onSelect={() => handlePlayerSelect()}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="player-search"
          options={getLeague()}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Regular option
            return option.label;
          }}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Search for a player" />
          )}
        />
      </SearchBar>
      <StatsStyle>
        Points: {stats?.points}
        <br></br>Goals: {stats?.goals}
        <br></br>Assists: {stats?.assists}
      </StatsStyle>
    </BasePage>
  );
};
