export type Player = {
  id: string;
  fullName: string;
  position: string;
};

export const getRosterByTeamId = async (id: string) => {
  const response = await fetch(
    `https://statsapi.web.nhl.com/api/v1/teams/${id}?expand=team.roster`
  );
  const roster: Player[] = [];
  const teamInfo = await response.json();
  const rawRoster = teamInfo.teams[0].roster.roster;
  for (const key in rawRoster) {
    const currentPlayer = {
      id: rawRoster[key].person.id,
      fullName: rawRoster[key].person.fullName,
      position: rawRoster[key].position.code,
    };
    roster.push(currentPlayer);
  }
  return roster;
};
