import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppRoutes } from "../constants";

const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: row;
  box-shadow: 1px 2px 2px -1px rgba(0, 0, 0, 0.2);
  margin: auto;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
`;

const NavButtton = styled(Button)`
  margin: 0 1rem 0 1rem;
`;

const Title = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
`;

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <NavbarContainer>
      <Title>NHL Stats</Title>
      <NavButtton onClick={() => navigate(AppRoutes.players)} variant="text">
        Players
      </NavButtton>
      <NavButtton onClick={() => navigate(AppRoutes.teams)} variant="text">
        Teams
      </NavButtton>
    </NavbarContainer>
  );
};
