export type Stats = {
  points: number;
  goals: number;
  assists: number;
};

export const getPointsById = async (id?: string) => {
  const response = await fetch(
    `https://statsapi.web.nhl.com/api/v1/people/${id}/stats?stats=statsSingleSeason&season=20222023`
  );
  const stats = await response.json();
  return stats.stats[0].splits.length > 0
    ? {
        points: stats.stats[0].splits[0].stat.points,
        goals: stats.stats[0].splits[0].stat.goals,
        assists: stats.stats[0].splits[0].stat.assists,
      }
    : {
        points: 0,
        goals: 0,
        assists: 0,
      };
};
