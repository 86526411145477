import { ReactNode } from "react";
import styled from "styled-components";

interface BasePageProps {
  children?: ReactNode;
  pageTitle: string;
}

const BasePageContainer = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
export const BasePage = ({ pageTitle, children }: BasePageProps) => {
  return (
    <BasePageContainer>
      <Content>{children}</Content>
    </BasePageContainer>
  );
};
