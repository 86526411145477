import "./App.css";
import styled from "styled-components";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "./components/Layout";
import { AppRoutes } from "./constants";
import { PlayerStatsPage } from "./pages/PlayerStats";
import { TeamStatsPage } from "./pages/TeamStats";

const StyledApp = styled.div``;
const Content = styled.div``;

export const App = () => {
  return (
    <BrowserRouter>
      <StyledApp>
        <Content>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path={AppRoutes.players} element={<PlayerStatsPage />} />
              <Route path={AppRoutes.teams} element={<TeamStatsPage />} />
              <Route path="*" element={<div>error page</div>} />
            </Route>
          </Routes>
        </Content>
      </StyledApp>
    </BrowserRouter>
  );
};

export default App;
